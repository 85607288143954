.spinCont {
  display: block;
  margin: 5px auto;
}

.ant-table-container .ant-table-tbody .ant-table-expanded-row .ant-table-cell {
  padding: 0 8px;
}

.headerOrderDetails {
  background: #484848;
  padding: 15px;
  .item {
    font-family: Gilroy, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #acacac;
    display: flex;
    align-items: center;
    button {
      border-color: #acacac;
      background: #acacac;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.bodyOrderDetails {
  background: #fff;
  padding: 15px;
  .item {
    font-family: Gilroy, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #273b42;
  }
}
