.objectHistoryCont {
  .productHistoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
      column-gap: 12px;
      .ant-select {
        width: 200px;
      }
    }
  }
  .ant-tabs-nav {
    padding: 0 1rem;
  }
  .historyTable {
    max-height: 70vh;
    overflow-y: scroll;
    .centerMaker {
      min-height: 40vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_cont {
      padding: 0 1rem;
      h3 {
        font-size: 14px;
        line-height: 17px;
        color: #7d8490;
        margin-bottom: 10px;
        background: #1c212d;
        color: white;
        padding: 5px 1rem;
        border-radius: 5px;
        &:not(:first-child) {
          margin-top: 1.5rem;
        }
      }
      p {
        display: flex;
        justify-content: space-between;
        span {
          // border: 1px solid #000;
          display: block;
          width: 30%;
          text-align: right;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #273b42;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding: 0 1rem;
    .ant-btn {
      .excel {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
