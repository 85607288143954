.filter {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__left {
    display: flex;
    align-items: center;
    gap: 1rem;

    .search {
      min-width: 20rem;
    }
  }

  :global {
    .ant-picker {
      min-width: 14rem !important;
    }
  }

  &__right {
    button {
      height: max-content !important;
      padding: 10px 16px;
    }
  }
}
