@import "../../../../index.scss";

.conversion_card {
  background: #1c212d;
  border-radius: rem(7);
  display: flex;
  flex-direction: column;
  row-gap: rem(10);
  width: rem(260);
  padding: rem(14) rem(16);
  transition: 0.2s linear;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: rem(28);
      height: rem(28);
      border-radius: 50%;
      background: #303541;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .payment {
      display: flex;
      align-items: center;
      .text {
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(17);
        color: #ffffff;
        margin-right: rem(5);
      }
      :global {
        .ant-input-affix-wrapper {
          background: #ffffff;
          border: rem(1) solid #d9d9d9;
          border-radius: rem(4);
          width: rem(90);
          height: rem(24);
          padding: 0 rem(5);
          input {
            height: 100%;
            font-size: rem(14);
            color: #303541;
          }
          .ant-input-suffix {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(17);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9e9e9e;
          }
        }
      }
    }
    .cancel {
      width: max-content;
      height: rem(18);
      border-radius: 50%;
      cursor: pointer;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      box-shadow: none;
      svg {
        transform: rotate(45deg);
        width: rem(18);
        height: rem(18);
      }
    }
  }
  .center {
    :global {
      .ant-select-selector {
        border: 0;
        background: transparent;
        // width: rem(110);
        width: max-content !important;
        font-weight: 600;
        font-size: rem(15);
        line-height: rem(18);
        color: #ffffff;
        height: rem(24);
        display: flex;
        align-items: center;
        padding: 0 rem(10) 0 0;
      }
      .ant-select-arrow {
        svg {
          // margin-left: rem(14);
          path {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
      .ant-select-selector {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    column-gap: rem(16);
    input {
      background: #222733;
      border: rem(1) solid #616c7a;
      border-radius: rem(4);
      width: rem(140);
      height: rem(30);
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(15);
      color: #ffffff;
    }
    :global {
      .ant-select-selector {
        border: 0;
        background: transparent;
        // width: rem(60);
        width: max-content !important;
        font-weight: 600;
        font-size: rem(15);
        line-height: rem(18);
        color: #ffffff;
        height: rem(24);
        display: flex;
        align-items: center;
        padding: 0 rem(10) 0 0;
      }
      .ant-select-arrow {
        svg {
          // margin-left: rem(14);
          path {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
      .ant-select-selector {
        border-color: transparent !important;
        box-shadow: none !important;
      }
      .ant-input-disabled {
        color: #ffffff !important;
      }
    }
  }
  .expense {
    display: flex;
    flex-direction: column;
    row-gap: rem(8);
    :global {
      .ant-checkbox-wrapper {
        font-weight: 500;
        width: rem(115) !important;
        font-size: rem(14);
        line-height: rem(15);
        color: #ffffff;
        display: flex;
        align-items: center;
      }
      .ant-checkbox-wrapper-disabled {
        span {
          color: #ffffff !important;
        }
      }
    }
    .check {
      height: max-content !important;
      display: flex !important;
      align-items: flex-end !important;
    }
    .payment_type {
      // width: rem(115) !important;
      width: max-content !important;
      .option {
        width: max-content !important;
      }
      :global {
        .ant-select-selector {
          border: 0;
          background: transparent;
          width: max-content !important;
          font-weight: 600;
          font-size: rem(15);
          line-height: rem(18);
          color: #ffffff;
          height: rem(24);
          display: flex;
          align-items: center;
          padding: 0 rem(10) 0 0 !important;
        }
        .ant-select-arrow {
          svg {
            path {
              fill: #ffffff;
              stroke: #ffffff;
            }
          }
        }
        .ant-select-selector {
          border-color: transparent !important;
          box-shadow: none !important;
        }
        option {
          width: max-content !important;
        }
      }
    }
    .text {
      display: flex;
      align-items: center;
    }
    .bottom {
      :global {
        .ant-checkbox-wrapper {
          font-weight: 500;
          width: rem(115) !important;
          font-size: rem(14);
          line-height: rem(15);
          color: #ffffff;
          display: flex;
          align-items: center;
        }
        .ant-select-selector {
          border: 0;
          background: transparent;
          // width: rem(60) !important;
          width: max-content !important;
          font-weight: 600;
          font-size: rem(15);
          line-height: rem(18);
          color: #ffffff;
          height: rem(24);
          display: flex;
          align-items: center;
          padding: 0 rem(10) 0 0;
        }
        .ant-select-arrow {
          svg {
            // margin-left: rem(14);
            path {
              fill: #ffffff;
              stroke: #ffffff;
            }
          }
        }
        .ant-select-selector {
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
