@import "../../../index.scss";

.expense {
  .top {
    display: flex;
    align-items: center;
    padding: rem(22) rem(40);
    justify-content: space-between;
    // font-family: "Inter" !important;
    h2 {
      font-weight: 250;
      font-size: rem(32);
      line-height: rem(38);
      color: #000000;
      margin: 0;
    }
    &_right {
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: rem(1) solid #d9d9d9;
        border-radius: rem(7);
        width: rem(108);
        height: rem(35);
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(17);
        color: #273b42;
        svg {
          margin-right: rem(10);
        }
        &:not(:last-child) {
          margin-right: rem(8);
        }
      }
    }
    .search {
      width: rem(405);
      height: rem(35);
      border: rem(1) solid #c6c6c6 !important;
      border-radius: rem(7);
      background: transparent;
      input {
        background: transparent !important;
        // font-family: "Inter" !important;
        &::placeholder {
          font-weight: 400;
          font-size: rem(13);
          line-height: rem(16);
          color: #c4ccca;
        }
      }
    }
  }
  .bottom {
    .table {
      :global {
        .ant-table-expanded-row {
          width: max-content !important;
          height: max-content !important;
        }
        .ant-table-cell {
          .status {
            &_active {
              color: #099e74;
            }
            &_passive {
              // color: #c82717;
              color: #2291cc;
            }
          }
          p {
            margin: 0;
            // font-weight: 600;
            // font-size: rem(12);
            line-height: rem(15);
            color: #273b42;
          }
          .btns {
            display: flex;
            align-items: center;
            button {
              background: transparent;
              border: 0;
              box-shadow: none;
              padding: 0;
              width: max-content;
              height: max-content;
              display: flex;
              align-items: center;
              justify-content: center;
              // &:first-child {
              //   margin-right: rem(18);
              // }
            }
          }
          font-size: rem(14);
        }
        .ant-table-thead {
          .ant-table-cell {
            border-radius: 0 !important;
            border-top: rem(1) solid rgba(198, 198, 198, 0.4) !important;
            border-color: rgba(198, 198, 198, 0.4);
            cursor: pointer;
          }
        }
      }
      &_expand_icon {
        cursor: pointer;
        svg {
          transition: 0.3s linear !important;
        }
      }
    }
    :global {
      .pagination_new {
        margin: rem(16);
      }
    }
  }
}

:global {
  .ant-modal-wrap {
    overflow-y: hidden;
  }
}

.warehouse {
  width: rem(1040) !important;
  @media only screen and (min-width: 1800px) {
    width: rem(1100) !important;
  }
  :global {
    // font-family: "Inter" !important;
    .ant-modal-content {
      height: rem(568) !important;
      @media only screen and (min-width: 1800px) {
        height: rem(700) !important;
      }
    }
    .ant-modal-title {
      p {
        margin: 0;
        span {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      top: rem(10);
      right: rem(30);
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-header {
      padding: rem(12) rem(16);
    }
    .ant-table-tbody {
      // .ant-table-cell {
      //   &:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      // }
      .ant-table-placeholder {
        .ant-table-cell {
          width: rem(1000) !important;
          @media only screen and (min-width: 1800px) {
            width: rem(1088) !important;
          }
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: rem(7) rem(16);
    align-items: center;
    background: #f0f5f9;
    .left {
      display: flex;
      align-items: center;
      // font-family: "Inter" !important;
      .new {
        background: transparent !important;
        border: 0;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: rem(1) solid #e2e7ea;
        border-radius: 0;
        margin-right: rem(10);
        padding-left: 0 !important;
        box-shadow: 0 !important;
        svg {
          margin-right: rem(8);
          opacity: 0.4;
        }
      }
      .icon {
        height: rem(38);
        display: flex;
        align-items: center;
      }
      .view,
      .sort,
      .download {
        height: rem(34);
        margin-right: rem(18);
        :global {
          .ant-select-selector {
            background: transparent !important;
            border: 0;
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(17);
            color: #273b42;
            box-shadow: none;
            .ant-select-selection-item {
              svg {
                width: rem(20);
                height: rem(20);
                path {
                  stroke: #273b42;
                }
              }
            }
            .ant-select-selection-placeholder {
              color: #273b42;
            }
          }
          .ant-select-arrow {
            width: rem(10);
            height: rem(10);
          }
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
            svg {
              margin-right: rem(8);
            }
          }
          .sort_option {
            div {
              div {
                display: flex !important;
                align-items: center !important;
              }
            }
            svg {
              opacity: 0;
            }
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: rem(6);
          path {
            transition: 0.3s linear;
          }
        }
      }
      .active {
        margin-right: rem(8);
        &_btn {
          background: #1c212d;
          color: #ffffff;
          border-color: #1c212d;
          path {
            stroke: #ffffff;
          }
        }
        &_not_btn {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          path {
            stroke: rgba(0, 0, 0, 0.25);
          }
        }
      }
      :global {
        .sort_select {
          label {
            cursor: pointer;
            height: 100% !important;
            display: flex;
            align-items: center;
            svg {
              width: rem(20);
              height: rem(20);
              path {
                stroke: #273b42;
              }
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      column-gap: rem(8);
      button {
        display: flex;
        align-items: center;
        margin-right: rem(16);
        // font-family: "Inter" !important;
        svg {
          margin-right: rem(10);
        }
      }
      .search {
        width: rem(266);
        height: rem(35);
        input {
          // font-family: "Inter" !important;
          background: transparent !important;
          &::placeholder {
            font-weight: 400;
            font-size: rem(13);
            line-height: rem(16);
            color: #c4ccca;
          }
        }
        :global {
          .ant-input-group-addon {
            // font-family: "Inter" !important;
            button {
              background: #e4ecf2;
              border: rem(1) solid #b4c9d9;
              svg {
                path {
                  fill: #273b42;
                }
              }
            }
          }
        }
      }
      .date {
        width: rem(221);
        height: rem(33);
        input {
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(17);
          color: #273b42;
          &::placeholder {
            color: #c4ccca;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: rem(469) !important;
    .warehouses {
      :global {
        .ant-table-thead {
          .ant-table-cell {
            cursor: pointer;
            &:hover {
              color: #099e74;
            }
          }
        }
        .ant-table-body {
          .ant-table-row {
            cursor: pointer;
          }
          .ant-table-cell {
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(17);
            color: #4d5a69;
            p {
              margin: 0;
              display: flex;
              align-items: center;
              svg {
                margin-right: rem(16);
              }
            }
            button {
              background: transparent !important;
              border: none;
              box-shadow: none;
              padding: 0;
              height: max-content;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .pagination_new {
          margin: rem(16);
        }
      }
    }
    .total {
      padding: rem(10) rem(12);
      border-top: rem(1) solid #bebebe;
      p {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(15);
        color: #868686;
        margin: 0;
      }
    }
  }
}

.warehouse_product {
  width: rem(900) !important;
  @media only screen and (min-width: 1800px) {
    width: rem(1012) !important;
  }
  :global {
    // font-family: "Inter" !important;
    .ant-modal-content {
      height: max-content !important;
    }
    .ant-table-tbody {
      .ant-table-cell {
        &:last-child {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
  .bottom {
    height: rem(400) !important;
  }
}

.warehouse_view {
  display: grid;
  padding: rem(16);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: rem(16);
  height: rem(344);
  overflow-y: scroll;
  overflow-x: hidden;
  @media only screen and (min-width: 1800px) {
    height: rem(470) !important;
  }
  .card {
    background: #ffffff;
    position: relative;
    width: rem(233);
    cursor: pointer;
    height: rem(149);
    border: rem(1) solid #d0d5dd;
    border-radius: rem(8);
    padding: rem(20);
    transition: 0.1s linear;
    &_top {
      margin-bottom: rem(27);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      div {
        display: flex;
        align-items: center;
        span {
          margin-right: rem(12);
        }
        p {
          margin: 0;
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(17);
          // text-align: center;
          color: #4d5a69;
          padding: 0 rem(10);
          button {
            display: none;
          }
        }
      }
      button {
        position: absolute;
        right: rem(-10);
        border: none;
        box-shadow: none;
        background: transparent;
        padding: 0 !important;
        width: max-content !important;
        height: max-content !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: rem(20);
          height: rem(20);
        }
      }
    }
    &_bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        p {
          display: flex;
          align-items: center;
          button {
            margin-left: rem(6);
          }
        }
      }
      button {
        background: transparent !important;
        border: none;
        box-shadow: none;
        padding: 0;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h3 {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(19);
        color: #4d5a69;
        margin-bottom: rem(8);
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(15);
        color: #4d5a69;
      }
    }
  }
}

.big_icon {
  grid-template-columns: repeat(5, 1fr) !important;
  grid-template-rows: repeat(3, 1fr);
  @media only screen and (min-width: 1800px) {
    grid-template-columns: repeat(6, 1fr) !important;
    grid-template-rows: repeat(4, 1fr) !important;
  }
  .card {
    width: rem(150);
    height: max-content;
    border-color: transparent;
    &_top {
      margin-bottom: 0;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        flex-direction: column;
        span {
          svg {
            width: rem(112) !important;
            height: rem(92) !important;
          }
        }
        p {
          width: rem(150);
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: rem(5);
            background: transparent;
            border: 0;
            padding: 0;
            height: max-content;
          }
        }
      }
    }
    &_bottom {
      display: none;
    }
  }
}

.product {
  background: #fbfbfb;
  padding: rem(20);
  :global {
    .ant-table {
      border-radius: 0;
      .ant-table-thead {
        .ant-table-cell {
          font-weight: 600;
          padding: rem(12) !important;
          font-size: rem(12);
          line-height: rem(15);
          text-transform: uppercase;
          color: #acacac;
          background: #484848;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: rem(12) !important;
          // font-family: "Gilroy";
          font-weight: 500;
          font-size: rem(12);
          line-height: rem(15);
          color: #273b42;
        }
      }
    }
  }
}

:global {
  .oneExpenseRow {
    border: rem(1) solid #34dba1;
    border-radius: rem(8);
    background: rgba(9, 158, 116, 0.15);
    margin-bottom: rem(15);
    .ant-col {
      padding: rem(10) rem(15);
      font-weight: 600;
      font-size: rem(12);
      line-height: rem(15);
      color: #099e74;
    }
  }
  .table_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      margin-left: rem(5);
      width: rem(16) !important;
      height: rem(16) !important;
      transition: 0.3s linear;
    }
  }
  .menu_context {
    display: flex;
  }
  .ant-dropdown-menu {
    overflow: hidden;
    padding: 0;
    .menu_item {
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
      color: #273b42;
      padding: 0;
      background: transparent;
      transition: 0.3s linear;
      span {
        div {
          display: flex;
          align-items: center;
          width: 100% !important;
          height: max-content !important;
          padding: rem(10) rem(16);
        }
        svg {
          margin-right: rem(5);
          margin-bottom: 0;
        }
      }
      cursor: pointer;
      &:hover {
        background: #e9ebec;
      }
    }
    .menu_exit {
      background: rgba(247, 71, 83, 0.15) !important;
      color: #f74753;
      border-bottom-left-radius: rem(7);
      border-bottom-right-radius: rem(7);
      transition: 0.3s linear;
      svg {
        transform: rotate(90deg);
        width: rem(15);
        height: rem(15);
        path {
          transition: 0.3s linear;
          stroke: #f74753;
        }
      }
      &:hover {
        color: #ffffff;
        background: #f74753 !important;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

.context_menu {
  // position: absolute;
  display: flex;
  z-index: 99999;
  // top: 0;
  // left: 0;
  transition: 0.3s linear;
  background: #ffffff;
  box-shadow: 0px 22px 70px rgba(120, 117, 112, 0.14),
    0px 9.19107px 29.2443px rgba(120, 117, 112, 0.10064),
    0px 4.91399px 15.6354px rgba(120, 117, 112, 0.083455),
    0px 2.75474px 8.76509px rgba(120, 117, 112, 0.07),
    0px 1.46302px 4.65507px rgba(120, 117, 112, 0.056545),
    0px 0.608796px 1.93708px rgba(120, 117, 112, 0.0393604);
  flex-direction: column;
  overflow: hidden;
  border-radius: rem(7);
  div {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(17);
    color: #273b42;
  }
  .title {
    padding: rem(10) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border-bottom: rem(1) solid #d9d9d9;
    svg {
      margin-right: rem(5);
    }
  }
  .item {
    padding: rem(10) rem(16);
    background: transparent;
    transition: 0.3s linear;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: rem(5);
    }
    &:hover {
      background: #e9ebec;
    }
    &_exit {
      // border: rem(1) solid rgba(255, 56, 48, 0.4);
      background: rgba(247, 71, 83, 0.15) !important;
      color: #f74753;
      border-bottom-left-radius: rem(7);
      border-bottom-right-radius: rem(7);
      transition: 0.3s linear;
      svg {
        transform: rotate(90deg);
        width: rem(15);
        height: rem(15);
        path {
          transition: 0.3s linear;
          stroke: #f74753;
        }
      }
      &:hover {
        color: #ffffff;
        background: #f74753 !important;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

:global {
  .sort_option {
    svg {
      opacity: 0 !important;
    }
  }
}
