@import "../../../../index.scss";

.payments {
  width: 100% !important;
  display: flex;
  gap: rem(8);
  flex-wrap: wrap;
  margin-bottom: rem(16);
  max-width: rem(650);
  .card {
    width: rem(206);
    background: #ffffff;
    border-radius: rem(8);
    padding: rem(8);
    display: flex;
    align-items: center;
    column-gap: rem(16);
    transition: 0.3s linear;
    &:hover {
      -webkit-box-shadow: 0px 0px 22px 0px rgba(34, 60, 80, 0.13);
      -moz-box-shadow: 0px 0px 22px 0px rgba(34, 60, 80, 0.13);
      box-shadow: 0px 0px 22px 0px rgba(34, 60, 80, 0.13);
    }
    .line {
      height: 100% !important;
      width: rem(4);
      border-radius: rem(12);
    }
    .content {
      display: flex;
      row-gap: rem(6);
      flex-direction: column;
      p {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(16);
        color: #464255;
        margin: 0;
        width: 100% !important;
      }
      h3 {
        width: 100%;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(23);
        color: #464255;
        margin: 0;
      }
    }
  }
}
