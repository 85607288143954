@import "../../../../index.scss";

.expense_modal {
  padding: 0 !important;
  .form {
    label {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(17);
      color: #7d8490;
      &::before {
        display: none !important;
      }
    }
    .building {
      display: flex;
      align-items: center;
      column-gap: rem(10);
      :global {
        .ant-form-item {
          width: 50%;
          margin-bottom: rem(10);
          .ant-select-selection-search,
          .ant-select-selection-placeholder {
            display: flex !important;
            align-items: center !important;
          }
          input {
            height: max-content !important;
          }
        }
      }
    }
    .top {
      display: flex !important;
      flex-direction: row !important;
      gap: rem(10) !important;
      :global {
        .name {
          width: 66% !important;
          margin: 0 0 rem(10) 0;
          .ant-checkbox-wrapper {
            margin: 0 rem(3);
          }
        }
        .date {
          margin-bottom: 0;
          width: 33% !important;
          .ant-picker {
            width: 100% !important;
          }
        }
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      column-gap: rem(10);
      :global {
        .ant-form-item {
          width: 32%;
          margin: 0 0 rem(10) 0;
        }
        .ant-select-disabled {
          opacity: 1;
        }
      }
    }
    .comments {
      margin-bottom: rem(16);
    }
    .price {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // padding-bottom: rem(10);
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: rem(14);
      button {
        background: #34dba1;
        border: rem(1) solid #22c38f;
        border-radius: rem(7);
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(17);
        color: #f8f8f9;
        box-shadow: none;
        &:first-child {
          background: transparent;
          padding: 0;
          width: max-content;
          height: max-content;
          color: #7d8490;
          border: 0;
        }
      }
    }
  }
  :global {
    // font-family: "Gilroy" !important;
    .ant-input-disabled {
      color: #273b42 !important;
    }
    .ant-picker-input {
      input {
        &:disabled {
          color: #273b42 !important;
        }
      }
    }
    .ant-modal-close {
      top: rem(16) !important;
      right: rem(30) !important;
      width: rem(20) !important;
      height: rem(20) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      .ant-modal-close-x {
        width: max-content;
        height: max-content;
      }
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal {
      top: rem(30) !important;
    }
    .ant-modal-body {
      padding: rem(14);
    }
    .ant-form-item-explain {
      display: none;
    }
    input,
    textarea,
    .ant-select-selection-item {
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
      color: #273b42;
      display: flex;
      align-items: center;
    }
    .ant-picker,
    input,
    textarea,
    .ant-select {
      height: rem(34);
    }
    .ant-select-selector {
      height: 100% !important;
    }
    .ant-modal-title {
      display: flex;
      align-items: center;
      line-height: rem(17);
      justify-content: space-between;
      > div {
        margin-right: 0;
        display: flex;
        align-items: center;
        line-height: rem(17);
        input {
          background: #ffffff;
          border: rem(1) solid #d9d9d9;
          border-radius: rem(7);
          margin: 0;
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(17);
          // color: #9e9e9e;
          color: #273b42;
          margin: 0 rem(5);
          height: rem(34);
          width: rem(98);
        }
      }
    }
  }
}

.card_info {
  width: max-content !important;
  h3 {
    background: #d8d8d8;
    width: 100%;
    padding: rem(3) rem(10);
    font-weight: 500;
    font-size: rem(12);
    line-height: rem(18);
    color: #717982;
    border-radius: rem(8) rem(8) 0 0;
  }
  .content {
    padding: rem(6) rem(12);
    &_info {
      h4 {
        font-weight: 600;
        font-size: rem(12);
        line-height: rem(18);
        color: #344054;
      }
      p {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(18);
        color: #667085;
      }
    }
  }
  :global {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-footer {
      display: none;
    }
  }
}
