@use "sass:math";
$BODY_FONT_SIZE: 16;

@function rem($pixels) {
  @return math.div($pixels, $BODY-FONT-SIZE) + rem;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Regular.eot");
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("./../src/fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Regular.woff") format("woff"),
    url("./../src/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-ExtraboldItalic.eot");
  src: local("Gilroy Extrabold Italic"), local("Gilroy-ExtraboldItalic"),
    url("./../src/fonts/Gilroy-ExtraboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-ExtraboldItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-ExtraboldItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("./../src/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Bold.woff") format("woff"),
    url("./../src/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("./../src/fonts/Gilroy-Black.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Black.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Black.woff") format("woff"),
    url("./../src/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Light.eot");
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("./../src/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Light.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Light.woff") format("woff"),
    url("./../src/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Semibold.eot");
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("./../src/fonts/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Semibold.woff") format("woff"),
    url("./../src/fonts/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Medium.eot");
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("./../src/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Medium.woff") format("woff"),
    url("./../src/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-MediumItalic.eot");
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("./../src/fonts/Gilroy-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-MediumItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-MediumItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-BlackItalic.eot");
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("./../src/fonts/Gilroy-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-BlackItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-BlackItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-UltraLight.eot");
  src: local("Gilroy UltraLight"), local("Gilroy-UltraLight"),
    url("./../src/fonts/Gilroy-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-UltraLight.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-UltraLight.woff") format("woff"),
    url("./../src/fonts/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-RegularItalic.eot");
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("./../src/fonts/Gilroy-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-RegularItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-RegularItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-SemiboldItalic.eot");
  src: local("Gilroy Semibold Italic"), local("Gilroy-SemiboldItalic"),
    url("./../src/fonts/Gilroy-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-SemiboldItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-SemiboldItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-HeavyItalic.eot");
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("./../src/fonts/Gilroy-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-HeavyItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-HeavyItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Extrabold.eot");
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("./../src/fonts/Gilroy-Extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Extrabold.woff") format("woff"),
    url("./../src/fonts/Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-BoldItalic.eot");
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("./../src/fonts/Gilroy-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-BoldItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-BoldItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-UltraLightItalic.eot");
  src: local("Gilroy UltraLight Italic"), local("Gilroy-UltraLightItalic"),
    url("./../src/fonts/Gilroy-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-UltraLightItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-UltraLightItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-LightItalic.eot");
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"),
    url("./../src/fonts/Gilroy-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-LightItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-LightItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Heavy.eot");
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("./../src/fonts/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Heavy.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Heavy.woff") format("woff"),
    url("./../src/fonts/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-Thin.eot");
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("./../src/fonts/Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
    url("./../src/fonts/Gilroy-Thin.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-Thin.woff") format("woff"),
    url("./../src/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./../src/fonts/Gilroy-ThinItalic.eot");
  src: local("Gilroy Thin Italic"), local("Gilroy-ThinItalic"),
    url("./../src/fonts/Gilroy-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./../src/fonts/Gilroy-ThinItalic.woff2") format("woff2"),
    url("./../src/fonts/Gilroy-ThinItalic.woff") format("woff"),
    url("./../src/fonts/Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-modal-content {
  border-radius: 9px 9px 7px 7px;
}

.ant-modal-header {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.anticon.anticon-close.ant-modal-close-icon {
  svg {
    display: none;
  }
}

/* Light uchun standart o'zgaruvchilar va qiymatlarni aniqlang */
$bgcolorlight: white;
$bluelight: #005fb8;
$inputBorderColorlight: #f7f7f7;
$inputTextColorlight: #bac0ca;
$sectionTitleColor: #222222;
$primaryColor: #34dba1;

/* Dark uchun standart o'zgaruvchilar va qiymatlarni aniqlang */
$bgcolordark: #2d2d2d;
$bluedark: #60cdff;
$inputBorderColordark: #f7f7f7;
$inputTextColordark: #bac0ca;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.coloredDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.ant-checkbox-inner {
  border-radius: 4px !important;
}
.centeredLoader {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.text-bold {
  font-weight: 600;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 66, 105, 0.06);
}

*::-webkit-scrollbar-thumb {
  background: rgba(0, 66, 105, 0.24);
  cursor: pointer;
}
.ant-rate-star-second {
  color: #bbbbbb;
}
.themeColor {
  color: $primaryColor;
}

.printerButton {
  border-color: #acacac;
  background: #acacac;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  svg {
    path {
      fill: #fff;
    }
  }
  &:hover {
    border-color: #acacac;
    background: #acacac;
  }
}

.d_f {
  display: flex;
}

.d_n {
  display: none !important;
}

.jc_sb {
  justify-content: space-between !important;
}

.jc_c {
  justify-content: center !important;
}

.jc_fe {
  justify-content: flex-end;
}

.w_100 {
  width: 100% !important;
}

.h_100 {
  height: 100% !important;
}

.ai_c {
  align-items: center;
}

.cg-10 {
  column-gap: 10px;
}

.cursor_p {
  cursor: pointer;
}

.p_0 {
  padding: 0 !important;
}

.m_0 {
  margin: 0 !important;
}

.w_mc {
  width: max-content !important;
}

.not_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent !important;
  padding: 0 !important;
  width: max-content;
  height: max-content;
  &:hover {
    background: transparent !important;
  }
}
