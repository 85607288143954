.mainCont {
  display: flex;
  background-color: #f8f7f8;
  width: 100% !important;
  .content {
    width: 100%;
    margin-left: 237px;
    min-height: 100vh;
    overflow-x: hidden;
  }
}
