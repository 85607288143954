.payModalCont {
  .ant-modal-body {
    padding: 0 0 10px 0;
  }
  .header {
    display: flex;
    background: #1C212D;
    justify-content: space-between;
    align-items: center;
    padding: 5px 1rem;
    border-radius: 7px 7px 0 0;
    height: 50px;
    .ant-row.ant-form-item {
      margin: 0;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      display: none;
    }
    .title {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #F8F7F8;
    }
    .inputCont {
      display: flex;
      align-items: center;
      span {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        margin: 0 5px;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
      }
      .input {
        font-family: Gilroy, serif;
        width: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #9E9E9E;
        text-align: right;
      }
    }
  }
  .body {
    margin: 20px 0.65rem 0 1rem;
    label {
      font-family: Gilroy, serif;
      font-style: normal;
      display: block;
      font-weight: 500;
      font-size: 1rem;
      line-height: 17px;
      color: #7D8490;
      margin-bottom: 5px;
    }
    .input {
      text-align: right;
      width: 95%;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 1rem 10px 1rem;
    .linkButton {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #7D8490;
    }
  }
}