@import '../../../index';

.settings {
  .buttonExcel {
    height: 35px;
    display: flex;
    align-items: center;
    font-family: Gilroy, serif;
    font-style: normal;
    font-size: 1rem;
    margin-right: 5px;
    span {
      margin-left: 5px;
    }
  }

  .buttonCont {
    padding: 0 10px;

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Gilroy, serif;
      font-style: normal;
      font-size: 1rem;
      color: #ffffff;

      .icon {
        display: inline-block;
      }

      span {
        color: #ffffff;
        margin-left: 10px;
      }
    }
  }

  .beforeHr {
    border-top: 0.5px solid #bebebe50;
    border-bottom: none;
    margin: 0 25px;
  }

  .afterHr {
    border-top: 0.5px solid #bebebe50;
    border-bottom: none;
    margin: 0;
  }

  .menuCont {
    display: flex;
    margin: 0 25px;

    .item {
      padding: 20px 0 0 0;
      margin: 0 10px;
      transition: 0.2s all ease;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        background: rgba(51, 215, 159, 0.15);
      }

      .activeTitle {
        padding: 0 10px;
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #273b42;
      }

      .notActiveTitle {
        padding: 0 10px;
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #7d8490;
      }

      .activeDiv {
        margin-top: 17px;
        width: 100%;
        background: #33d79f;
        border-radius: 5px 5px 0 0;
        height: 3px;
      }

      .notActiveDiv {
        margin-top: 17px;
        width: 100%;
        background: transparent;
        border-radius: 5px 5px 0 0;
        height: 3px;
      }
    }
  }

  .tableIcon {
    cursor: pointer;
    margin: 0 3px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
}

.editModal {
  padding-bottom: 0;
  .footer {
    display: flex;
    justify-content: flex-end;

    margin-top: 0.4rem;
    .ant-btn {
      margin: 0 0.3rem;
      .btn.excel {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          path {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition-property: all;
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            transition-delay: 0s;
          }
        }
        span {
          font-weight: 600;
        }
      }
      &:hover {
        .btn.excel {
          svg {
            path {
              stroke: #33d79f;
            }
          }
        }
      }
    }
  }
  .modalFooterBack {
    color: #7d8490;
  }

  .modalFooterBack:hover {
    color: $primaryColor !important;
  }

  label {
    font-family: Gilroy, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    display: block;
    line-height: 17px;
    color: #7d8490;
    margin-bottom: 5px;
  }

  .input {
    border-radius: 7px;
  }

  .select {
    width: 100%;

    .ant-select-selector {
      border-radius: 7px;
    }
  }

  .openPasswordButton {
    display: block;
    margin-right: 1rem;
  }

  .footerCont {
    text-align: end;
  }
}

.objectsTable {
  .ant-table-row:hover {
    cursor: pointer;
  }
}
