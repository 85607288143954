.productGraphCont {
  .body {
    display: flex;
    align-items: flex-start;
    .distribution {
      flex: 1;
      padding: 10px;
      .titleLabel {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 17px;
        color: #7d8490;
      }
      .title {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
        color: #273b42;
        margin: 10px 0;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 17px;
        color: #273b42;
        margin: 10px 0;
        cursor: default;
        .lighter {
          opacity: 0.6;
          margin-left: 5px;
        }
      }

      .companyCountsTitle {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #7d8490;
        margin: 10px 0;
      }

      .scroll {
        height: 270px;
        overflow-y: auto;
        padding-right: 10px;
      }
    }
  }

  .border {
    min-height: 398px;
    margin: 0 10px;
    transform: translateY(-11px);
    width: 1px;
    background-color: #b7b7b7;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #616c7a;
    }
  }
}

.graph {
  flex: 2;
  .title {
    font-family: Gilroy, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7d8490;
    margin: 10px;
  }
  &_content {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      border-radius: 40px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 66, 105, 0.06);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 66, 105, 0.24);
      cursor: pointer;
    }
  }
  .tooltip {
    border-right: 10px !important;
    background: #ffffff;
    width: 200px;
    display: flex !important;
    padding: 0 10px 0 0;
    box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08),
      0 8px 16px rgba(50, 50, 71, 0.06);
    .itemCont {
      display: flex;
      align-items: center;
      height: 30px;
      justify-content: space-between;
      .title {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: normal;
        color: #666666;
      }
      .data {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #11263c;
      }
    }
  }
}

.productGraphContSpin {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
}
