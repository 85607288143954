
.table {

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 1rem;
  }
}