@import "../../../../variables";

.table {
  .tableIcon {
    cursor: pointer;
    margin: 0 3px;
  }
  .starCont {
    display: flex;
    align-items: center;
    span {
      margin-right: 4px;
      display: inline-block;
    }
  }
}

.supplier-table {
  .table-row {
    cursor: pointer;
  }
  .table-edit {
    display: inline-block;
    height: 100%;
    &:hover svg path {
      stroke: $themeGreen;
    }
  }
  .ant-table-cell {
    padding: 12px;
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-left: 5px;
      }
    }
  }
}

.partsTable {
  .partsTableTag {
    cursor: default;
    display: block;
    margin: 0 auto;
    text-align: center;
    min-width: 80px;
    padding: 5px 5px;
    border-radius: 3px;
  }
}

.collapse {
  background: transparent;
  .ant-collapse-content-box {
    padding: 0 0 10px 0;
  }
  .ant-collapse-item {
    border: 0;
    .ant-collapse-header {
      width: max-content !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #2291cc;
      padding-left: 10px;
      svg {
        margin-left: 10px;
      }
    }
    .ant-picker {
      width: 100%;
    }
  }
}
