@import "./src/index";

.searchHeaderCont {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 25px 20px 40px;
  .title {
    font-family: Gilroy, serif;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 38px;
    color: $sectionTitleColor;
  }

  .input {
    width: 380px;
    border-radius: 7px;
    padding: 5px 10px;
    background-color: #fbfbfb;
    .ant-input {
      background-color: #fbfbfb;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #273b42;
      margin-left: 7px;
    }
  }
}

.afterHr {
  border-top: 0.5px solid #bebebe50;
  border-bottom: none;
  margin: 0;
}

.paginationCont {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);

  .ant-pagination {
    background: #ffffff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 10px;
    width: max-content;
  }
  .showChanger {
    display: flex;
    align-items: center;
    column-gap: 15px;
    h4 {
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      color: #717982;
    }
    .ant-select-selection-item {
      // font-size: 14px;
      // line-height: 100%;
      font-weight: 600;
      color: #273b42;
    }
  }

  .ant-pagination-item {
    border: 1px solid transparent;
    transition: 0.2s all ease-in;
    a {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: #273b42;
    }
  }

  .ant-pagination-item:hover {
    border: 1px solid $primaryColor;
  }

  .ant-pagination-item-active {
    background: $primaryColor;
    border-radius: 3px;
    a {
      font-family: Gilroy, serif;
      font-style: normal;
      color: #ffffff !important;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: 1px solid transparent;
      transition: 0.2s all ease-in;

      .anticon {
        color: #616c7a;
      }
    }
  }

  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    .ant-pagination-item-link {
      border: 1px solid $primaryColor;
    }
  }
}

.pagination_new {
  .ant-pagination {
    display: flex;
    align-items: center;
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      background: #ffffff;
      // border: rem(1) solid #d0d5dd;
      border: rem(1) solid rgba(9, 158, 116, 0.15);
      border-radius: rem(8) 0 0 rem(8);
      display: flex;
      align-items: center;
      width: max-content;
      justify-content: center;
      padding: rem(16) rem(12);
      margin: 0;
      transition: 0.2s linear;
      &:hover {
        // background: #e4ecf2;
        background: rgba(9, 158, 116, 0.15);
      }
      a {
        font-weight: 500 !important;
        font-size: rem(14) !important;
        line-height: rem(20) !important;
        color: #1d2939 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter" !important;
        svg {
          margin-right: rem(8);
        }
      }
    }
    .ant-pagination-item {
      border-radius: 0 !important;
    }
    .ant-pagination-item-active {
      // background: #e4ecf2;
      background: rgba(9, 158, 116, 0.15);
    }
    .ant-pagination-next {
      border-radius: 0 rem(8) rem(8) 0 !important;
      svg {
        margin: 0 0 0 rem(8) !important;
      }
    }
    .ant-pagination-disabled {
      opacity: 0.55;
    }
  }
}
