@import "../../../../index";

.card {
  padding: rem(13) rem(15) rem(10) rem(15);
  background: #1c212d;
  border-radius: rem(8);
  width: rem(264);
  overflow: hidden;
  position: relative;
  transition: 0.3s linear;
  cursor: default;
  z-index: 1;
  &::before {
    content: "";
    transition: 0.3s linear;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 110%;
    height: 0;
    transition: 0.3s linear;
  }
  .top {
    margin-bottom: rem(9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      background: #303541;
      transition: 0.1s linear;
      border-radius: 50%;
      width: rem(36);
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(36);
    }
    .right {
      background: rgba(48, 53, 65, 0.3);
      border-radius: rem(30);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(7) rem(10);
      button {
        border: 0;
        box-shadow: none;
        width: rem(24) !important;
        height: rem(24) !important;
        padding: 0 !important;
        border-radius: 50%;
        background: transparent;
        svg {
          width: rem(24) !important;
          height: rem(24) !important;
        }
      }
    }
  }
  .bottom {
    p {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(17);
      color: #dadada;
      margin-bottom: rem(9);
    }
    h3 {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(22);
      color: #ffffff;
      span {
        margin-left: rem(8);
      }
    }
  }
  .current_sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(22);
      color: #ffffff;
      span {
        margin-left: rem(8);
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0 !important;
      padding: 0 !important;
      svg {
        width: rem(20);
        transform: rotate(-90deg);
        height: rem(20);
        path {
          stroke: #fff;
        }
      }
    }
  }
}
.uysot_card {
  background: #099e74;
  &:hover {
    &::before {
      background: #1c212d;
    }
  }
  .top {
    .icon {
      background: #007957;
    }
    .date {
      color: #efefef;
      font-weight: 500;
      font-size: rem(12);
      line-height: rem(14);
      border: rem(1) solid #f5f5f5;
      border-radius: rem(3);
      position: relative;
      width: rem(110);
      height: rem(22);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .retry {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        opacity: 1;
        transition: 0.3s linear;
        height: 100%;
        width: 100%;
        cursor: pointer;
        svg {
          margin-right: rem(5);
          width: rem(12) !important;
          height: rem(12) !important;
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s linear;
        position: absolute;
        top: 0;
        opacity: 1;
        height: 100%;
        width: 100%;
      }
      &:hover {
        .text {
          opacity: 0;
          top: 100%;
        }
        .retry {
          top: 0;
          opacity: 1;
        }
      }
    }
  }
}

.general_card {
  height: rem(158);
  .bottom {
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly !important;
  }
}
