@import "../../../../index.scss";

.integration {
  padding: rem(20);
  width: max-content;
  .add_card {
    width: rem(248);
    cursor: pointer;
    height: rem(155);
    background: #ffffff;
    transition: 0.3s ease-in-out;
    border-radius: rem(8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: rem(1) solid transparent;
    &:hover {
      border: rem(1) solid #273b42;
      box-shadow: 0 rem(5) rem(11) rgba(173, 173, 173, 0.15);
    }
    svg {
      margin-bottom: rem(8);
      width: rem(32);
      height: rem(32);
    }
    p {
      width: rem(117);
      font-weight: 500;
      font-size: rem(12);
      line-height: rem(15);
      text-align: center;
      color: #273b42;
    }
  }
  &_modal {
    width: max-content !important;
    :global {
      .ant-modal-body {
        padding: 0;
      }
      .ant-modal-footer {
        display: none;
      }
    }
    .form {
      width: max-content;
      display: flex;
      flex-direction: column;
      row-gap: rem(20);
      background: #ffffff;
      border-radius: 0 0 rem(7) rem(7) !important;
      .info {
        width: max-content !important;
        display: flex;
        column-gap: rem(53);
        padding: rem(20) rem(20) 0 rem(20);
        .left {
          .content {
            display: flex;
            column-gap: rem(16);
            .left {
              display: flex;
              flex-direction: column;
              row-gap: rem(16);
              p {
                background: #ffffff;
                border: rem(1) solid #d9d9d9;
                border-radius: rem(7);
                width: rem(130);
                padding: rem(10);
                margin: 0;
                font-weight: 600;
                font-size: rem(14);
                height: rem(34);
                line-height: rem(17);
                display: flex;
                align-items: center;
                color: #273b42;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
              row-gap: rem(16);
              :global {
                .ant-select-selector {
                  background: #ffffff;
                  border: rem(1) solid #d9d9d9;
                  border-radius: rem(7);
                  width: rem(180);
                  height: rem(34);
                  font-weight: 600;
                  font-size: rem(14);
                  line-height: rem(17);
                  color: #273b42;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          .switchs {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: rem(16);
            .item {
              height: rem(34);
              display: flex;
              align-items: center;
            }
          }
        }
        .center {
          .sum {
            display: flex;
            flex-direction: column;
            row-gap: rem(16);
            div {
              height: rem(34);
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: rem(30);
              span {
                font-weight: 600;
                font-size: rem(14);
                line-height: 100%;
                color: #273b42;
              }
            }
          }
        }
      }
      .footer {
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
        column-gap: rem(10);
        background: #ececec;
        padding: rem(15) rem(13);
        border-radius: 0 0 rem(7) rem(7) !important;
      }
      h4 {
        font-weight: 600;
        font-size: rem(15);
        line-height: rem(18);
        color: #7d8490;
        margin-bottom: rem(26);
      }
    }
  }
  .cards {
    display: flex;
    column-gap: rem(20);
  }
}
