//@import "";

.pictures {
  width: max-content !important;
  :global {
    .ant-modal-footer {
      display: none;
    }
    .ant-upload-list {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      background: #fff;
      border-radius: 0 0 8px 8px;
      overflow: hidden;
    }
  }

  .top {
    padding: 10px 16px;

    .upload {
      .text {
        p {
          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #6941C6;
          }

          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #475467;
        }
      }
    }
  }

  .bottom {
    padding: 0 16px;
    .table {
      transition: .3s ease-in-out !important;
      .file_name {
        display: flex;
        align-items: center;
        column-gap: 12px;
        width: max-content !important;
        input {
          background: transparent;
          border: 1px solid transparent;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101828;
          width: max-content;
        }
        svg {
          width: 40px !important;
          height: 40px !important;
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 4px;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
      .text_center {
        //width: 100%;
        text-align: center;
      }
      :global {
        .ant-table-thead {
          .ant-table-cell {
            background: #F9FAFB;
            border-bottom: 1px solid #EAECF0;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #475467;
            &:before {
              display: none;
            }
          }
        }
        .ant-table-tbody {
          transition: .3s ease-in-out !important;
          height: max-content !important;
          .ant-table-cell {
            padding: 10px;
            color: #475467;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            button {
              background: transparent;
              border: 0;
              width: max-content;
              height: max-content;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: none;
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .ant-table-row {
          height: max-content;
          transition: .3s ease-in-out;
        }
      }
    }
  }

  .footer {
    width: 100% !important;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 16px;
    background: #F8F8F9;
  }
}