@import "../../../index.scss";

:root {
  --white: #fff;
  --blue: #0078d2;
  --black: #000;
  --dark: #273b42;
  --light-green: #ebfbf6;
  --light-blue: rgba(55, 114, 255, 0.1);
  --green: #33d79f;
}

.order_detailed {
  padding: rem(20) rem(30);
  font-family: "Gilroy" !important;
  background: var(--white);

  h2,
  h3,
  h4,
  p {
    margin: 0;
    font-family: "Gilroy" !important;
  }

  h4 {
    display: flex;
    align-items: center;
    column-gap: rem(4);
  }

  .text {
    font-weight: 500;
    color: var(--dark);
    font-size: rem(12);
    line-height: 100%;
    width: 100%;
    font-family: "Gilroy" !important;

    span {
      &:first-child {
        text-align: end;
        margin-right: rem(4);
      }
    }

    &_flex {
      display: grid;
      align-items: start;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .bold {
    font-weight: 600;
    color: var(--black);
    line-height: 120%;
    font-size: rem(12);
    font-family: "Gilroy" !important;
  }

  .top {
    display: flex;
    flex-direction: column;
    row-gap: rem(10);
    margin: 0 0 rem(8) 0;
    align-items: center;
    text-align: center;
    font-family: "Gilroy" !important;

    h2 {
      margin: 0;
      text-transform: uppercase;
      font-size: rem(18);
    }

    h4 {
      text-align: center !important;
    }
  }

  .info {
    margin-bottom: rem(8);
    font-family: "Gilroy" !important;

    h4 {
      margin-bottom: rem(10);
    }
  }

  .table {
    margin-bottom: rem(20);
    font-family: "Gilroy" !important;

    :global {
      .ant-table-content {
        border: rem(1) solid var(--light-green) !important;
        border-right: 0;
        border-bottom: 0;
        border-radius: 0;
        overflow-y: hidden;

        .ant-table-thead {
          .ant-table-cell {
            border-bottom: rem(1) solid var(--light-green);
            border-right: rem(1) solid var(--light-green);
            background: transparent;
            font-size: rem(12);
            padding: rem(5) rem(10) !important;

            &::before {
              display: none;
            }
          }
        }

        .ant-table-tbody {
          .ant-table-cell {
            border-bottom: rem(1) solid var(--light-green);
            border-right: rem(1) solid var(--light-green);
            font-size: rem(12);
            padding: rem(5) rem(10) !important;
          }
        }

        .ant-table-summary {
          .ant-table-cell {
            padding: rem(5) rem(10) !important;
          }
        }
      }

      .ant-table-footer {
        background: var(--white);
        border: rem(1) solid var(--light-green);
        border-radius: 0;
        padding: 0;
      }
    }

    .footer_text {
      width: 100%;
      height: 100%;
      // border-right: rem(1) solid var(--light-blue);
      display: flex;
      align-items: center;
      justify-content: end;
      padding: rem(10) rem(40) rem(10) 0;
    }

    .sum {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: rem(10) 0 rem(10) rem(10);
      border-left: rem(1) solid var(--light-green);
    }
  }

  .check {
    h4 {
      margin-bottom: rem(10);
    }
  }

  .owner {
    margin: rem(20) 0 0 0;

    .card {
      width: 100%;
      border: rem(5) solid var(--light-green);
      padding: rem(10);
      border-radius: rem(14);
      display: flex;
      flex-direction: column;
      row-gap: rem(12);

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          font-size: rem(12);
          font-family: "Gilroy" !important;
        }
      }

      h3 {
        text-align: center;
        font-weight: 600;
        color: var(--green);
        border-bottom: rem(1) solid var(--light-green);
        padding-bottom: rem(6);
        font-size: rem(15);
        font-family: "Gilroy" !important;
      }

      &_2 {
        border-color: var(--light-blue) !important;
        font-family: "Gilroy" !important;

        h3 {
          color: var(--blue) !important;
          font-family: "Gilroy" !important;
        }
      }
    }
  }
}
