.productHistoryModal {
  min-width: 1000px;
  padding-bottom: 0;
  .ant-modal-body {
    padding: 0 0 10px 0;
  }
  .productHistoryHeader {
    display: flex;
    background: #1c212d;
    justify-content: space-between;
    align-items: center;
    padding: 5px 1rem;
    border-radius: 7px 7px 0 0;
    height: 50px;
    .title {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      color: #f8f7f8;
    }
    .date {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      color: #f8f7f8;
    }
  }
  .productHistoryFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 1rem 0 1rem;
    .leftSection {
      display: flex;
      align-items: center;
      .label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #616c7a;
        margin-right: 10px;
      }
      .sum {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #273b42;
        margin-right: 10px;
      }
      .iconSuccess {
        margin-right: 5px;
      }
      .iconDanger {
        transform: rotate(180deg);
        margin-right: 5px;
      }
    }
    .cancel {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #616c7a;
    }
    .pdfBtn {
      margin: 0 0.3rem;
    }
    .excelBtn {
      margin: 0 0.3rem;
    }
    .button {
      display: flex;
      align-items: center;
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #273b42;
      span {
        margin-left: 10px;
      }
    }
  }
  .supplier-table {
    .price {
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
}

.productHistoryMenu {
  width: 18rem;
  .rangePicker {
    width: 100%;
  }
}

.importExcelPopover {
  .ant-popover-arrow {
    display: none;
  }
}
