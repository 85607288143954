@import "../../../../variables";

.supplier {
  &_body {
    padding: 0.875rem;
    display: flex;
    // flex-wrap: nowrap;
    .errorTag {
      cursor: default;
      border: 1px solid #ff3830;
      box-sizing: border-box;
      border-radius: 7px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      span {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #ff3830;
        margin-left: 5px;
      }
    }
    .warningTag {
      cursor: default;
      border: 1px solid #ecca38;
      box-sizing: border-box;
      border-radius: 7px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      span {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #ecca38;
        margin-left: 5px;
      }
    }
    .successTag {
      cursor: default;
      border: 1px solid #099e74;
      background: #099e74;
      box-sizing: border-box;
      border-radius: 7px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      span {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #f8f7f8;
        cursor: default;
        margin-left: 5px;
      }
    }
    .oneSupplierTableHeader {
      display: flex;
      justify-content: space-between;
      background: #1c212d;
      border-radius: 7px;
      height: 40px;
      align-items: center;
      width: 100%;
      .cont {
        display: flex;
        align-items: center;
        .cub {
          width: 20px;
          height: 20px;
          margin: 0 10px;
          background: #33d79f;
          border: 1px solid #33d79f;
          box-sizing: border-box;
          border-radius: 4px;
        }
        .title {
          margin-left: 35px;
          font-family: Gilroy, serif;
          font-style: normal;
          font-weight: lighter;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
        .sum {
          font-family: Gilroy, serif;
          font-style: normal;
          font-weight: lighter;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin-right: 35px;
        }
        //.button {
        //  cursor: pointer;
        //  user-select: none;
        //  border: 1px solid #33D79F;
        //  box-sizing: border-box;
        //  border-radius: 7px;
        //  display: flex;
        //  align-items: center;
        //  padding: 3px 5px;
        //  margin: 0 10px;
        //  span {
        //    font-family: Gilroy, serif;
        //    font-style: normal;
        //    font-weight: 600;
        //    font-size: 14px;
        //    line-height: 17px;
        //    color: #33D79F;
        //  }
        //}
      }
    }
    .actReconciliation {
      background: #ffffff;
      box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08),
        0 8px 16px rgba(50, 50, 71, 0.06);
      border-radius: 7px;
      width: 23rem;
      margin-top: 1rem;
      padding: 0.8rem;
      .rangePicker {
        width: 100%;
      }
    }
    .oneSupplierTable {
      //width: 716px;
      width: 100%;
      .ant-table-tbody {
        .ant-table-cell {
          background: #ffffff;
        }
      }
    }
  }
  &_card {
    //height: 16rem;
    //width: max-content;
    //width: 100;
    min-width: 24vw;
    height: max-content;
    border-radius: 1.25rem;
    border: 4px solid $themeDarkBlue;
    color: white;
    background-color: $themeDarkBlue;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
    .creditCard {
      position: absolute;
      z-index: 10;
      top: 30%;
      right: 7%;
    }
    &_body {
      transform: scale(1.01);
      margin-bottom: -0.5rem;
      position: relative;
      z-index: 2;
      border-radius: 1.25rem;
      background-color: $themeDarkBlue;
      padding: 1.5rem;
    }
    &_header {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .title {
        span {
          font-size: 0.675rem;
          color: #d7d7d7;
        }
        p {
          font-weight: 600;
        }
      }
    }
    &_balans {
      p {
        margin-bottom: 0;
        font-weight: lighter;
      }
      span {
        font-weight: 300;
        font-size: 1.5rem;
      }
      .price {
        display: inline-block;
        margin-right: 1rem;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
    &_button {
      background-color: $themeGreen;
      text-align: center;
      padding-top: 1.3rem;
      padding-bottom: 1.05rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: $themeDarkBlue;
      &:hover {
        background-color: rgba($themeGreen, 0.95);
        cursor: pointer;
      }
    }
  }
}
.last_payment {
  max-width: 301px !important;
  .ant-modal-body {
    padding: 0;
  }
  &_content {
    display: flex;
    flex-direction: column;
  }
  &_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
    min-height: 29px;
    padding: 8px 12px;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #717982;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      color: #273b42;
      margin: 0;
    }
  }
  &_address {
    font-size: 14px;
    line-height: 15px;
    color: #444444;
    border-top: 1px solid #eaeaea;
    font-weight: 400;
    padding: 12px;
  }
  &_footer {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 12px;
  }
}
