@import '../../../variables';
.stats {
  margin: 1.25rem;
  &-tooltip {
    width: 200px;
    background: white;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.cover {
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid $themeGreen;
  box-shadow: 0 2px 2px rgba(0, 32, 51, 0.04);
  &_head {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    &.objects {
      justify-content: flex-end;
    }
  }
}

.topProducts {
  margin-bottom: 2rem;
  &_chart {
    height: 250px;
    width: 100%;
  }
}

.expenseStats {
  padding-left: 0 !important;
  padding-right: 1rem !important;
  .spinnerChart {
    display: block;
    text-align: center;
    margin-top: 5rem;
    width: 100%;
  }
  .tooltip {
    border-right: 10px !important;
    background: #ffffff;
    width: 200px;
    padding: 0 10px 0 0;
    box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08), 0 8px 16px rgba(50, 50, 71, 0.06);
    .itemCont {
      display: flex;
      align-items: center;
      height: 30px;
      justify-content: space-between;
      .title {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin-left: 10px;
        color: #666666;
      }
      .data {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #11263c;
      }
    }
  }
  &_head {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &_card {
    flex: 1;
    height: 85px;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    &.green_card {
      margin-right: 0.5rem;
      // background: url('../../../image/greenCard.png') no-repeat;
    }
    &.red_card {
      margin-left: 0.5rem;
      // background: url('../../../image/redCard.png') no-repeat;
    }
  }
  &_dk {
    color: white;
    position: absolute;
    padding: 0.5rem 1rem;
    .text {
      margin-bottom: 0.5rem;
    }
    .eye {
      margin-bottom: 0.25rem;
      &:hover {
        cursor: pointer;
      }
    }
    .balans {
      font-size: 1.4rem;
      font-weight: 600;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  &_body {
    height: 450px;
    &_head {
      display: flex;
      align-items: center;
    }
  }
}

.productStats {
  display: flex;
  overflow-x: hidden;
  height: 600px;
  &_left {
    border-right: 1px solid #cdcdcd;
    padding: 1rem;
    flex: 1;
    .spinnerChart {
      display: block;
      text-align: center;
      margin-top: 8rem;
      width: 100%;
    }
    .emptyChart {
      margin-top: 6rem;
    }
    .tooltip {
      border-right: 10px !important;
      background: #ffffff;
      width: 200px;
      padding: 0 10px 0 0;
      box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08), 0 8px 16px rgba(50, 50, 71, 0.06);
      .itemCont {
        display: flex;
        align-items: center;
        height: 30px;
        justify-content: space-between;
        .title {
          font-family: Gilroy, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          margin-left: 10px;
          color: #666666;
        }
        .data {
          font-family: Gilroy, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          color: #11263c;
        }
      }
    }
  }
  &_right {
    margin-right: 1rem;
    flex: 1;
    padding: 1rem 0 1rem 1rem;
    .spinnerChart {
      display: block;
      text-align: center;
      margin-top: 10rem;
      width: 100%;
    }
    .cont {
      display: block;
      padding-left: 20px;
      .title {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        color: #273b42;
      }
      .body {
        display: flex;
        justify-content: space-between;
        .info {
          margin-top: 3rem;
          .title {
            font-family: Gilroy, serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #7d8490;
            margin-top: 10px;
          }
          .count {
            font-family: Gilroy, serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #273b42;
          }

          &.building {
            margin-top: 0;
            .info_cont {
              display: flex;
              .first {
                margin-right: 3rem;
              }
              .title {
                margin-top: 1rem;
                margin-bottom: -0.2rem;
              }
              .count {
                margin-bottom: 0;
              }
            }
          }
        }
        .labelsCont {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          .label {
            display: flex;
            align-items: center;
            margin-right: 20px;
            span {
              width: 8px;
              height: 8px;
              margin-right: 10px;
              border-radius: 50%;
              display: inline-block;
            }
            p {
              margin: 0;
              padding: 0;
              font-family: Gilroy, serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #616c7a;
            }
          }
        }
        .chart {
          width: 20rem;
          height: 350px;
          margin: 0 auto;
          .tooltip {
            border-right: 10px !important;
            background: #ffffff;
            width: 200px;
            padding: 0 10px 0 0;
            box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08),
              0 8px 16px rgba(50, 50, 71, 0.06);
            .itemCont {
              display: flex;
              align-items: center;
              height: 30px;
              justify-content: space-between;
              .title {
                font-family: Gilroy, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                margin-left: 10px;
                color: #666666;
              }
              .data {
                font-family: Gilroy, serif;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                color: #11263c;
              }
            }
          }
        }
        &.building {
          flex-direction: column;
        }
      }
    }
  }
}

.left {
  &_head {
    display: flex;
    justify-content: space-between;
  }
}

.ObjectStats_chart {
  .spinnerChart {
    display: block;
    text-align: center;
    margin-top: 10rem;
    width: 100%;
  }
  .bodyCont {
    display: flex;
    .chart {
      .tooltip {
        border-right: 10px !important;
        background: #ffffff;
        width: 200px;
        padding: 0 10px 0 0;
        box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08),
          0 8px 16px rgba(50, 50, 71, 0.06);
        .itemCont {
          display: flex;
          align-items: center;
          height: 30px;
          justify-content: space-between;
          .title {
            font-family: Gilroy, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-left: 10px;
            color: #666666;
          }
          .data {
            font-family: Gilroy, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            color: #11263c;
          }
        }
      }
      width: 26vw;
      height: 380px;
    }
  }
  .rightSection {
    margin-top: 1rem;
    padding-top: 1rem;
    overflow-y: scroll;
    height: 350px;
    display: flex;
    width: 25vw;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    .cont {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 20px;
      .name {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #616c7a;
      }
      .value {
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #616c7a;
      }
    }
  }
  .labelsCont {
    display: flex;
    padding: 20px 20px 0 20px;
    flex-wrap: wrap;
    .label {
      display: flex;
      align-items: center;
      margin-right: 20px;
      span {
        width: 12px;
        height: 12px;
        margin-right: 10px;
        border-radius: 50%;
        display: inline-block;
      }
      p {
        margin: 0;
        padding: 0;
        font-family: Gilroy, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #616c7a;
      }
    }
  }
}

.editModal {
  &.Debit .ant-modal-header {
    background-color: $danger !important;
  }
  &.Kredit .ant-modal-header {
    background: $themeGreen !important;
  }
}

//expand table
.expandTable {
  margin-left: 5rem;
}
