.product {
  position: relative;

  .tabs {
    padding: 0 1rem;
  }
  :global {
    .ant-tabs-tab-btn {
      font-weight: bold;
      font-size: 16px;
      font-family: "Gilroy";
    }
  }
}
