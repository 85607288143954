.menuComponent {
  min-width: 237px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  height: 100vh;
  top: 0;
  background-color: #1c212d;
  padding-top: 34px;
  .logoUyqurUz {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .logo {
      width: 60px;
      height: 60px;
      background-color: white;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h1 {
      padding: 0;
      font-family: Gilroy, serif;
      margin: 0;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #f8f7f8;
    }
    p {
      color: #fff;
      margin: 0;
    }
  }
  .menu {
    width: 100%;
    height: 436px;
    // border: 1px solid #000;
    margin: 40px auto 10px;

    h2 {
      font-family: Gilroy, serif;
      display: inline-block;
      margin-left: 31px;
      font-weight: 500;
      font-size: 12px;
      color: #616c7a;
      margin-bottom: 12px;
    }
    ul {
      padding: 0;
      li {
        font-family: Gilroy, serif;
        position: relative;
        cursor: pointer;
        // border: 1px solid #000;
        // border-radius: 10px;
        align-items: center;
        display: flex;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        list-style: none;
        padding: 11px;
        padding-left: 27px;
        color: #616c7a;
        &.active,
        &:hover {
          list-style: circle;
          color: #33d79f;
          span {
            svg {
              path {
                stroke: #33d79f;
              }
            }
          }
          .ellipse {
            transform: translateX(0);
          }
        }
        .liIcons {
          margin-right: 10px;
        }
        .ellipse {
          position: absolute;
          left: 0;
          transform: translateX(-110%);
          transition: transform 0.2s ease-in-out;
        }
      }
    }
  }
  .logOut {
    display: flex;
    // border: 1px solid #000;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    .logoLogOut {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #c4c4c4;
    }
  }
}
