@import "../../index.scss";

.LoginPage {
  background-image: url("../../image/loginBackgroundLight.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  // background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .login {
    width: 294px;
    // height: 395px;
    background-color: $bgcolorlight;
    box-shadow: 0 85px 186px rgba(188, 188, 188, 0.35),
      0 25.625px 56.0736px rgba(188, 188, 188, 0.25),
      0 10.6433px 23.2901px rgba(188, 188, 188, 0.2),
      0 3.84948px 8.42357px rgba(188, 188, 188, 0.15);
    padding: 25px 22px;

    .logo {
      text-align: center;
      // display: inline-block;
      margin: 0 auto;

      h1 {
        margin: 0;
        padding: 0;
        display: inline-block;
        font-family: Gilroy, serif;
      }
    }

    h3 {
      font-family: Gilroy, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      margin: 32px auto;
      text-align: center;
      color: #099e74;
    }

    .formLogin {
      //inputStyle
      .ant-row {
        height: 45px;
        border-radius: 7px;
        margin-bottom: 16px !important;

        .ant-col {
          height: 100%;
          border-radius: 7px;

          .ant-form-item-control-input {
            height: 100%;
            min-height: 45px;
            border-radius: 7px;

            .ant-form-item-control-input-content {
              height: 100%;
              border-radius: 7px;
              // border: 1px solid #000;
              .input {
                height: 100%;
                border-radius: 7px;
              }
            }
          }
        }
      }

      //checkbox
      .checkbox {
        width: 130px;
        font-family: Gilroy, serif;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        // border: 1px solid #000;
        .ant-col {
          width: 100%;
          max-width: unset;
          margin-left: 0 !important;

          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;

            .ant-checkbox-wrapper {
              color: #bac0ca !important;
            }
          }
        }
      }

      //Button

      .submitLogin {
        display: inline-block;

        width: unset;
        height: unset;
        font-family: Gilroy, serif;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;

        .ant-col {
          width: unset;
          margin-left: 0;
          height: 100%;
          .ant-form-item-control-input {
            width: unset;
            height: unset;

            .ant-form-item-control-input-content {
              width: unset;
              height: unset;

              .ant-btn {
                background-color: #34dba1;
                // border: 1px solid $bluelight;
                padding: 8px 28px;
                width: unset;
                height: unset;
              }
            }
          }
        }
      }
    }
  }
  .isError {
    color: red;
    font-family: Gilroy, serif;
    font-weight: 400;
    font-size: 14px;
    padding: 0 5px;
    display: inline-block;
  }
}

.LoginPage.dark {
  background-image: url("../../image/loginBackgroundDark.png");

  .login {
    background-color: $bgcolordark;

    h3 {
      color: $bluedark;
    }

    .formLogin {
      .checkbox {
        .ant-checkbox {
          .ant-checkbox-inner,
          .ant-checkbox-inner:hover,
          .ant-checkbox-inner:focus {
            background-color: $bgcolordark;
            border: 1px solid $bluedark;
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner,
          .ant-checkbox-inner:hover,
          .ant-checkbox-inner:focus {
            background-color: $bluedark;
            border: 1px solid $bluedark;
          }

          .ant-checkbox-inner::after {
            border: 2px solid $bgcolordark;
            border-top: 0;
            border-left: 0;
          }
        }
      }

      .input {
        background-color: $bgcolordark;

        .ant-input-password-icon {
          svg {
            fill: $inputBorderColordark;
          }
        }

        .ant-input {
          background-color: $bgcolordark;
        }
      }

      .submitLogin {
        .ant-col {
          .ant-form-item-control-input {
            .ant-btn {
              background-color: $bluedark;
              border: 1px solid $bluedark;
            }
          }
        }
      }
    }
  }
}
