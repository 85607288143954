@import "../../../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  row-gap: rem(12);
  height: 100vh;
  &_header {
    display: flex;
    justify-content: space-between;
    padding: rem(24) 0;
    margin: 0 rem(24);
    border-bottom: rem(0.5) solid #bebebe;
    .left {
      display: flex;
      align-items: center;
      column-gap: rem(20);
      span {
        display: flex;
        align-items: center;
        height: 100% !important;
        svg {
          cursor: pointer;
          width: rem(24);
          height: rem(24);
          &:hover path {
            stroke: $primaryColor;
          }
          path {
            fill: #099e74;
            transition: 0.2s ease-in-out;
          }
        }
      }
      h1 {
        font-weight: 250;
        font-size: rem(32);
        line-height: 0.8;
        color: $sectionTitleColor;
        margin: 0;
      }
    }
    .right {
      display: flex;
      column-gap: 1rem;
      align-items: center;
      .submit_btn {
        display: flex;
        align-items: center;
        column-gap: rem(10);
        svg {
          path {
            stroke: #273b42;
          }
        }
        span {
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(17);
          color: #273b42;
        }
      }
      .submit_btn_active {
        background: #099e74;
        color: #ffffff;
        display: flex;
        align-items: center;
        column-gap: rem(10);
        border-color: #099e74;
        svg {
          path {
            stroke: #ffffff;
          }
        }
        span {
          color: #ffffff !important;
        }
      }
      .edit_btn {
        transition: 0.3s ease-in-out;
        border: 0;
        box-shadow: none;
        background: transparent;
        width: rem(30);
        height: rem(30);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: rem(4);
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }
  &_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 rem(43) rem(20) rem(43);
    &_top {
      display: flex;
      column-gap: 1rem;
      :global {
        .ant-form-item {
          height: max-content;
          &-label {
            label {
              font-weight: 500;
              font-size: rem(14);
              line-height: rem(17);
              color: #868686;
              &::before {
                display: none !important;
              }
            }
          }
          .ant-select {
            width: rem(300);
            height: rem(33);
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(17);
            color: #273b42;
          }
        }
        .ant-picker {
          width: rem(170);
          input {
            height: rem(23) !important;
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(17);
            color: #273b42;
          }
        }
      }
    }
    &_bottom {
      flex: 1;
      display: flex;
      column-gap: 1rem;
      .search {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
        width: rem(417);
        label {
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(17);
          color: #868686;
          width: 100%;
          .all {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: rem(10);
            width: 100%;
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: rem(10) rem(8);
            }
          }
        }
        &_top {
          margin: 0;
        }
        &_bottom {
          display: flex;
          flex-direction: column;
          background-color: $bgcolorlight;
          border: rem(1) solid #d9d9d9;
          box-shadow: 0 rem(2) rem(2) rgba(0, 32, 51, 0.04);
          border-radius: rem(7);
          overflow-y: scroll;
          height: 54vh;
          .checkbox {
            width: 100%;
            display: flex;
            flex-direction: column;
            :global {
              .ant-checkbox-wrapper {
                margin: 0;
                height: rem(44);
                width: 100%;
                padding: rem(12);
                font-weight: 500;
                font-size: rem(16);
                line-height: rem(19);
                color: #273b42;
              }
            }
          }
          :global {
            .ant-checkbox-group {
              display: flex;
              flex-direction: column;
              height: 100%;
              .ant-checkbox-wrapper {
                margin: 0;
                height: rem(44);
                width: 100%;
                padding: rem(12);
                font-weight: 500;
                font-size: rem(16);
                line-height: rem(19);
                color: #273b42;
              }
            }
          }
        }
      }
      .table {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
        // width: max-content !important;
        // width: rem(660);
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          border: rem(1) solid #d9d9d9;
          border-radius: rem(7);
          overflow: hidden;
          :global {
            .ant-table-thead {
              border-bottom: rem(1) solid #d9d9d9 !important;
              tr {
                th {
                  background: #ffffff !important;
                  font-weight: 600;
                  font-size: rem(12);
                  line-height: rem(15);
                  text-transform: uppercase;
                  color: #616c7a;
                  &::before {
                    display: none;
                  }
                }
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                &:hover {
                  td {
                    background: transparent !important;
                  }
                }
              }
            }
            .ant-table-cell {
              border-bottom: 0;
              font-weight: 500;
              font-size: rem(12);
              line-height: rem(15);
              color: #273b42;
              input {
                font-weight: 500;
                font-size: rem(12);
                line-height: rem(15);
                color: #273b42;
                width: 80%;
              }
              button {
                background: transparent;
                border: 0;
                width: max-content;
                height: 100% !important;
                display: flex;
                padding: 0;
                align-items: center;
                justify-content: center;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.settings {
}
