@import "../../../index.scss";

.checkout {
  .search {
    width: rem(405);
    height: rem(35);
    background: #fbfbfb;
    border: rem(1) solid #c6c6c6;
    border-radius: rem(7);
    margin: 0 0 0 rem(12);
    input {
      background: transparent !important;
      &::placeholder {
        background: transparent;
      }
    }
  }
  :global {
    .swiper {
      padding: rem(16) rem(0) rem(9) rem(12);
    }
    .swiper-wrapper {
      width: 100% !important;
      display: flex;
      // gap: rem(16);
      row-gap: rem(16);
    }
    .swiper-slide {
      width: max-content !important;
    }
  }
  .cards {
    .card {
      padding: rem(13) rem(15) rem(10) rem(15);
      background: #1c212d;
      border-radius: rem(8);
      width: rem(264);
      overflow: hidden;
      position: relative;
      transition: 0.3s linear;
      cursor: default;
      z-index: 1;
      &::before {
        content: "";
        transition: 0.3s linear;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 110%;
        height: 0;
        transition: 0.3s linear;
      }
      .top {
        margin-bottom: rem(9);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          background: #303541;
          transition: 0.1s linear;
          border-radius: 50%;
          width: rem(36);
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(36);
        }
        .right {
          background: rgba(48, 53, 65, 0.3);
          border-radius: rem(30);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: rem(7) rem(10);
          button {
            border: 0;
            box-shadow: none;
            width: rem(24) !important;
            height: rem(24) !important;
            padding: 0 !important;
            border-radius: 50%;
            background: transparent;
            svg {
              width: rem(24) !important;
              height: rem(24) !important;
            }
          }
        }
      }
      .bottom {
        p {
          font-weight: 400;
          font-size: rem(14);
          line-height: rem(17);
          color: #dadada;
          margin-bottom: rem(9);
        }
        h3 {
          font-weight: 600;
          font-size: rem(18);
          line-height: rem(22);
          color: #ffffff;
          span {
            margin-left: rem(8);
          }
        }
      }
    }
    .uysot_card {
      background: #099e74;
      &:hover {
        &::before {
          background: #1c212d;
        }
      }
      .top {
        .icon {
          background: #007957;
        }
        .date {
          color: #efefef;
          font-weight: 500;
          font-size: rem(12);
          line-height: rem(14);
          border: rem(1) solid #f5f5f5;
          border-radius: rem(3);
          position: relative;
          width: rem(110);
          height: rem(22);
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          .retry {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 100%;
            opacity: 1;
            transition: 0.3s linear;
            height: 100%;
            width: 100%;
            cursor: pointer;
            svg {
              margin-right: rem(5);
              width: rem(12) !important;
              height: rem(12) !important;
            }
          }
          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s linear;
            position: absolute;
            top: 0;
            opacity: 1;
            height: 100%;
            width: 100%;
          }
          &:hover {
            .text {
              opacity: 0;
              top: 100%;
            }
            .retry {
              top: 0;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .table {
    width: 100% !important;
    .kirim {
      text-align: center;
      width: max-content !important;
    }
    .kassa {
      > div {
        background: #f1f9f9;
        border-radius: rem(16);
        width: max-content;
        padding: rem(3) rem(10);
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(20);
        text-align: center;
        color: #099e74;
      }
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  :global {
    .pagination_new {
      margin: rem(10) 0 rem(10) rem(8);
    }
    .ant-table-thead {
      th {
        border-radius: 0 !important;
        border-top: rem(0.5) solid #bebebe !important;
        border-bottom: 0 !important;
        font-weight: 600;
        font-size: rem(12);
        line-height: rem(15);
        color: #616c7a;
        > span {
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            margin-left: rem(5);
          }
        }
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          background: rgba(48, 53, 65, 0.1);
          td {
            background: transparent;
          }
        }
        td {
          background: #ffffff;
          transition: 0.3s linear !important;
          border-bottom: rem(1) solid #d7d7d7;
          font-weight: 600;
          font-size: rem(12);
          line-height: rem(15);
          color: #273b42;
          button {
            padding: 0;
            border: 0;
            box-shadow: none;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
          }
        }
      }
    }
    .ant-modal {
      top: rem(30) !important;
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: rem(16);
    margin-bottom: rem(11);
    padding-right: rem(16);
    .left {
      display: flex;
      align-items: center;
      column-gap: rem(8);
    }
    .result {
      display: flex;
      align-items: center;
      background: #efefef;
      column-gap: rem(10);
      border-radius: rem(8);
      padding: rem(8) rem(10);
      p {
        font-weight: 600;
        font-size: rem(14);
        margin: 0;
        line-height: rem(17);
        color: #273b42;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(8);
        background: transparent;
        box-shadow: none;
        border: 0;
        height: max-content;
        width: max-content;
        padding: 0;
        svg {
          margin: 0;
        }
      }
    }
    @media only screen and (max-width: 1366px) {
      max-width: rem(1120);
    }
    button {
      background: #ffffff;
      border: rem(1) solid #d9d9d9;
      border-radius: rem(7);
      height: rem(35);
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
      color: #273b42;
      justify-content: center;
      svg {
        margin-right: rem(10);
      }
    }
  }
}

.filtr {
  :global {
    .ant-modal-body {
      padding: rem(20);
    }
  }
  .footer {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
    margin: rem(10) 0;
    column-gap: rem(16);
    button {
      box-shadow: none;
      &:first-child {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(17);
        color: #616c7a;
        background: transparent;
        border: 0;
      }
      &:last-child {
        background: #ffffff;
        border: rem(1) solid #d9d9d9;
        border-radius: rem(7);
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(17);
        color: #273b42;
      }
    }
  }
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      row-gap: rem(8);
    }
    .ant-row {
      margin: 0;
      display: flex !important;
      flex-direction: column !important;
      row-gap: rem(8) !important;
      .ant-form-item-label {
        padding: 0 !important;
        label {
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(17);
          color: #7d8490;
        }
      }
      .ant-picker {
        width: 100%;
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
}
