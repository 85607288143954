@import "../../../variables";

.cOrder {
  font-family: "Gilroy";
  &_backIcon {
    font-size: 1.5rem;
    display: inline-block;
    margin-right: 1rem;
  }
  &_headerBtn.button .anticon {
    margin: 0;
    display: flex;
  }
  .button {
    font-size: 14px;
    line-height: 17px;
    color: #273b42;
  }
  &_paymentBtn {
    width: 100%;
    padding: 7px 15px;
    height: unset;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_total {
    font-size: 1.125rem;
    color: $themeGreen;
    font-weight: 600;
  }
  .importExcel {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
  }
}

.orderForm {
  margin: 1rem 20px 0;
  &_products {
    background-color: white;
    overflow-y: scroll;
    border-radius: 7px;
    width: 100%;
    height: calc(100vh - 18rem);
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 2px rgba(0, 32, 51, 0.04);
  }
  .ant-picker {
    width: 100%;
  }
}

.productList {
  // &_checkbox {
  // }
  &_checkboxes {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin-left: 0;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    .ant-checkbox-inner {
      border-radius: 4px;
    }
  }
  &_head {
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    button {
      font-weight: 500;
      font-size: 1rem;
      color: $themeGreen;
    }
  }
  &_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.paymentModal {
  width: unset !important;
  max-width: 1200px;
  &_block {
    // height: 200px;
    // padding: 0 1rem;
    p {
      margin: 0 !important;
    }
    .block_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        color: $themeGreen;
      }
      p {
        font-size: 1rem;
        margin-left: 0.5rem !important;
        color: #868686;
      }
    }
    .block_table {
      margin: 0 0.5rem;
    }
  }
  &_expanses {
    border-right: 1px solid #d9d9d9;
  }
  &_paymentTypes {
    padding-left: 10px;
  }
  &_footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    button:first-child {
      margin-right: 1rem;
    }
  }
}

.alertPanel {
  background-color: #f74753;
  padding: 0.5rem;
  color: white;
  font-weight: 500;
}

.partsTable {
  .ant-table-content {
    .ant-table-thead {
      .ant-table-cell {
        div {
          font-size: 13px;
          cursor: pointer;
          display: flex;
          align-items: center;
          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .table_action {
    display: flex;
    .picture {
      padding: 0;
      border: 0;
      width: max-content;
      height: max-content !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      box-shadow: none;
    }
    .copy {
      width: 24px;
      height: 24px !important;
    }
  }
}

.ant-modal-wrap {
  overflow-y: auto !important;
  padding-top: 20px;
}

.unvModal {
  width: max-content !important;
}

.importModal {
  width: max-content !important;
  &_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #475467;
    width: 100%;
    margin: 0 0 20px 0;
  }
  &_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    button {
      &:first-child {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}

.uploadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  column-gap: 10px;
  svg {
    path {
      transition: 0.3s ease-in-out;
    }
  }
  &_active {
    svg {
      transform: rotate(180deg);
      path {
        stroke: #fff;
      }
    }
  }
  &:hover {
    color: #099e74;
    svg {
      path {
        stroke: #099e74;
      }
    }
  }
}

.notUploadCount {
  background: #f04438;
  mix-blend-mode: multiply;
  border-radius: 16px;
  padding: 0 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-left: 10px;
}

.nds {
  &_button {
    background: transparent !important;
    width: 24px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    box-shadow: none;
    height: auto;
  }
  .ant-form-item {
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }
}

.product_name_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #273b42;
  }
}

.searchHeaderCont {
  .title {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    color: #222222;
    margin: 0;
  }
  button {
    &:hover {
      color: #099e74;
      svg {
        path {
          stroke: #099e74;
        }
      }
    }
  }
}

.orderForm {
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #868686;
    &:before {
      display: none !important;
    }
  }
  .ant-select-selection-item {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: #273b42;
  }
  .ant-picker-input {
    input {
      font-size: 14px;
      font-weight: 600;
      color: #273b42;
    }
  }
  .productList {
    width: 100%;
    .ant-input-suffix {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .productList_head {
      padding: 9px 0;
      margin-bottom: 12px;
      border-bottom: 1px solid rgba(0, 65, 102, 0.2);
      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #099e74;
        padding: 12px rem(30);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
  .productList_checkboxes {
    padding-bottom: 10px;
    .ant-checkbox-wrapper {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #002033;
    }
    .ant-checkbox-input,
    .ant-checkbox-inner {
      width: 20px !important;
      height: 20px !important;
    }
    .ant-checkbox-inner {
      &:after {
        height: 10px;
        width: 6px;
        top: 40%;
        left: 25% !important;
      }
    }
  }
  &_table {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    .ant-table-thead {
      .ant-table-cell {
        background: #fff;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #616c7a;
        padding: 18px 14px;
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #273b42;
        padding: 17px 14px;
        border: 0;
        input {
          font-weight: 500;
          font-size: 14px;
          color: #273b42;
        }
      }
    }
    .ant-table-cell {
      padding: 0.8rem !important;
    }
  }
  .total {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #868686;
    span {
      margin-left: 8px;
    }
  }
}
