@import "../../../index.scss";

.conversion {
  display: flex;
  flex-direction: column;
  row-gap: rem(21);
  padding-top: rem(22);
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(25) 0 rem(40);
    h2 {
      font-weight: 250;
      font-size: rem(32);
      line-height: rem(38);
      color: #000000;
      margin: 0;
      display: flex;
      align-items: center;
      button {
        margin-right: rem(10);
        background: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        width: max-content;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          height: rem(24) !important;
          width: rem(24) !important;
        }
        svg {
          height: 100%;
          width: 100%;
          margin: 0;
          path {
            fill: #34dba1 !important;
          }
        }
      }
    }
    button {
      height: rem(35);
      background: #ffffff;
      border: rem(1) solid #d9d9d9;
      border-radius: rem(7);
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
      color: #273b42;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: rem(7);
      }
    }
  }
  .bottom {
    :global {
      .ant-table-thead {
        th {
          border-radius: 0 !important;
          border-top: rem(0.5) solid #bebebe !important;
          border-bottom: 0 !important;
          font-weight: 600;
          font-size: rem(12);
          line-height: rem(15);
          color: #616c7a;
          > span {
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              margin-left: rem(5);
            }
          }
          &::before {
            display: none;
          }
        }
      }
      .ant-table-tbody {
        tr {
          &:hover {
            background: rgba(48, 53, 65, 0.1);
            td {
              background: transparent;
            }
          }
          td {
            background: #ffffff;
            transition: 0.3s linear !important;
            border-bottom: rem(1) solid #d7d7d7;
            font-weight: 600;
            font-size: rem(12);
            line-height: rem(15);
            color: #273b42;
            button {
              padding: 0;
              border: 0;
              box-shadow: none;
              height: max-content;
              width: max-content;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
            }
          }
        }
      }
    }
    .table {
      .valuta_name {
        margin-left: rem(8);
      }
    }
  }
  :global {
    .paginationCont {
      width: max-content;
    }
  }
}

.create_conversion {
  width: max-content !important;
  :global {
    .ant-modal-footer {
      display: none !important;
    }
    .ant-input-disabled {
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
      color: #273b42 !important;
      opacity: 1;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: #ffffff !important;
      background: transparent !important;
    }
    .ant-modal-body {
      max-height: 90vh;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: rem(10);
  }
  .content {
    display: flex;
    column-gap: rem(23);
    height: max-content;
    overflow-y: auto !important;
    max-height: rem(570);
    margin-bottom: rem(10);
    @media only screen and (max-width: 1400px) {
      max-height: rem(290);
    }
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    .center {
      height: rem(200) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: max-content;
        height: max-content !important;
        padding: 0;
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      row-gap: rem(8);
      margin-bottom: rem(16);
      .add {
        background: #ffffff;
        border: rem(1) solid #d9d9d9;
        border-radius: rem(7);
        width: 100%;
        font-weight: 600;
        font-size: rem(14);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: rem(17);
        color: #273b42;
        height: rem(35);
        svg {
          margin-right: rem(10);
          path {
            stroke: #273b42;
          }
        }
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      row-gap: rem(10);
    }
  }
}
