.historyPaper {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  margin-bottom: 1rem;
  &_nodata {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
  }
  &__title {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-bottom: 1px solid #d9d9d9;
    div {
      margin: 0 !important;
    }
  }
  &__content {
    padding: 0.5rem 1rem;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    > span {
      display: block;
      margin-right: 1rem;
    }
    button {
      margin-left: auto;
      border: none;
    }
  }
  &__tag {
    padding: px;
    border-radius: 4px;
    width: 8rem;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
}
.history {
  padding: 20px;
}
